<script lang="ts" setup>
  import type { PromotionRule, WebPromotion } from './MPromotions.types'
  import {
    HEIGHT_MOBILE_CAROUSEL,
    HEIGHT_DESKTOP_CAROUSEL,
    WIDTH_DESKTOP_CAROUSEL,
    WIDTH_DESKTOP_SOLID_PEEK,
    WIDTH_MOBILE_CAROUSEL,
    MIN_SLIDE_COUNT,
    SLIDES_PER_VIEW_DESKTOP,
    SLIDES_PER_VIEW_MOBILE,
    SPACE_BETWEEN
  } from './MPromotions.constants'
  import {
    isTypeDatePromotionRule,
    isTypeRelishPromotionRule,
    isTypeStatePromotionRule,
    isTypeTagPromotionRule,
    isTypeTimePromotionRule,
    type TypePromotionComponent
  } from '@/types/contentful'
  import type { ImgLoading } from '@/types/media'
  import { useOrderingStore } from '@/stores'
  import { definedItems } from '@/utils/contentful'
  import { transformKeysToLowercase } from '@/utils/object'

  const props = withDefaults(
    defineProps<{
      data: TypePromotionComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>
      loading?: ImgLoading
      preload?: boolean
    }>(),
    {
      loading: 'lazy',
      preload: false
    }
  )

  const { slides } = useSlides()
  const { hasButtons, isDarkMode } = useSlideConfig()
  const { module, carouselScale } = useSliderConfig()
  const { relishCheck, dateRangeCheck, dayOfWeekAndTimeCheck, tagCheck } = useConditionalDisplay()

  /**
   * @description Checks if the promotion rules pass and returns a list of promotions
   */
  function useSlides() {
    function ensureString(input: any) {
      const typeofIsString = typeof input === 'string'
      const instanceofString = input instanceof String

      if (!typeofIsString && !instanceofString) {
        throw new TypeError('bad input for promotion rule; expected string, got ' + typeof input)
      }
    }

    function ensureArray(input: any) {
      if (!Array.isArray(input)) {
        throw new TypeError('bad input for promotion rule; expected string, got ' + typeof input)
      }
    }

    function ensureNumber(input: any) {
      if (!(typeof input === 'number')) {
        throw new TypeError('bad input for promotion rule; expected string, got ' + typeof input)
      }
    }

    function doesRulePass(rule: PromotionRule): boolean {
      if (isTypeDatePromotionRule(rule)) {
        ensureString(rule.fields.dateFrom)
        ensureString(rule.fields.dateTo)

        return dateRangeCheck(rule.fields.dateFrom as string, rule.fields.dateTo as string)
      } else if (isTypeTimePromotionRule(rule)) {
        ensureArray(rule.fields.day)
        ensureNumber(rule.fields.timeFrom)
        ensureNumber(rule.fields.timeTo)

        return dayOfWeekAndTimeCheck(
          rule.fields.day as string[],
          rule.fields.timeFrom as number,
          rule.fields.timeTo as number
        )
      } else if (isTypeStatePromotionRule(rule)) {
        // TODO: state rule is unimplemented due to having no good way to determine user's state
        return false
      } else if (isTypeRelishPromotionRule(rule)) {
        if (rule.fields.visibility !== 'Guest' && rule.fields.visibility !== 'Relish') {
          throw new TypeError('garbage input received from promotion relish rule')
        }
        return relishCheck(rule.fields.visibility)
      } else if (isTypeTagPromotionRule(rule)) {
        const orderingStore = useOrderingStore()
        // If the user is not signed in, the rule does not pass (tag rules only apply to Relish users)
        if (!orderingStore.user.isSignedIn) {
          return false
        }

        ensureString(rule.fields.path)
        ensureString(rule.fields.value)

        const userTags = transformKeysToLowercase(toRaw(orderingStore.user.tags))

        return tagCheck(userTags, {
          path: rule.fields.path as string,
          value: rule.fields.value as string
        })
      } else {
        throw new Error('somehow a promotion rule was not any of the rule types')
      }
    }

    function allRulesPass(rules: (PromotionRule | undefined)[]): boolean {
      const liveRules = rules?.filter((rule): rule is PromotionRule => rule !== undefined)
      return !liveRules.map(doesRulePass).includes(false)
    }

    // the swiper doesn't behave well with a small number of looping slides, so we have to pad them out by repeating them
    const slides = computed<WebPromotion[]>(() => {
      const promos = definedItems(props.data.fields.items)!
        // filter the promotions according to the display rules
        .filter((promotion) => promotion.fields.rules === undefined || allRulesPass(promotion.fields.rules))

      let slides = promos

      // zero or one slide don't actually loop so no need to pad
      if (slides.length < 2) {
        return slides
      }

      while (slides.length < MIN_SLIDE_COUNT) {
        slides = slides.concat(promos)
      }
      return slides
    })

    return {
      slides
    }
  }

  /**
   * @description Defines the configuration for the swiper component
   */
  function useSliderConfig() {
    const module = ref(null)
    const moduleWidth = useElementBounding(module).width

    // calculate the factor to scale down the swiper by
    const carouselScale = computed(() => {
      const denominator = mdAndUp.value ? WIDTH_DESKTOP_SOLID_PEEK : WIDTH_MOBILE_CAROUSEL
      // the formula is (the actual current width of the carousel / the width of the carousel as it fits in the page at full width)
      return moduleWidth.value / denominator
    })

    return {
      carouselScale,
      module
    }
  }

  /**
   * @description Defines the configuration for the slide content
   */
  function useSlideConfig() {
    function hasButtons(slide: WebPromotion) {
      return slide.fields.primaryButton || slide.fields.secondaryButton
    }

    // TODO: this should be per-promotion
    const isDarkMode = computed<boolean>(() => props.data.fields.base?.fields.theme === 'Dark')

    return {
      hasButtons,
      isDarkMode
    }
  }
</script>

<template>
  <section v-if="slides.length > 0" ref="module" class="m-promotions module-paddings-v">
    <div class="promotional-carousel-wrapper">
      <client-only>
        <Swiper
          :autoplay="{
            delay: 6000,
            disableOnInteraction: false
          }"
          :breakpoints="{
            0: {
              allowTouchMove: true,
              navigation: {
                enabled: false
              },
              slidesPerView: SLIDES_PER_VIEW_MOBILE
            },
            768: {
              allowTouchMove: false,
              navigation: {
                enabled: true
              },
              slidesPerView: SLIDES_PER_VIEW_DESKTOP
            }
          }"
          :modules="[SwiperNavigation]"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }"
          :space-between="SPACE_BETWEEN"
          :speed="500"
          centered-slides
          class="promotional-carousel"
          loop
        >
          <SwiperSlide v-for="(slide, i) in slides" :key="i" class="promotional-slide">
            <CImageBlock
              v-if="slide.fields.desktopImage?.fields.file"
              :alt="slide.fields.desktopImage.fields.description"
              :loading
              :preload
              :src="slide.fields.desktopImage.fields.file?.url"
              class="desktop-image"
            />
            <CImageBlock
              v-if="slide.fields.mobileImage?.fields.file"
              :alt="slide.fields.mobileImage.fields.description"
              :loading
              :preload
              :src="slide.fields.mobileImage.fields.file?.url"
              class="mobile-image"
            />

            <div
              :class="[
                'section-text',
                {
                  'prop-align-left': slide.fields.text?.fields.alignment === 'Left',
                  'prop-align-center': slide.fields.text?.fields.alignment === 'Centre',
                  'prop-align-right': slide.fields.text?.fields.alignment === 'Right',
                  'prop-justify-start': slide.fields.alignment === 'Top',
                  'prop-justify-center': slide.fields.alignment === 'Middle',
                  'prop-justify-end': slide.fields.alignment === 'Bottom'
                }
              ]"
            >
              <CTextBlock
                :dark-mode="isDarkMode"
                :text="slide.fields.text"
                class="promotion-text-block"
                overflow-wrap="normal"
              />

              <CButtonBlock
                v-if="hasButtons(slide)"
                :dark="isDarkMode"
                :primary-button="slide.fields.primaryButton"
                :secondary-button="slide.fields.secondaryButton"
                class="button-block"
                flex-direction="row"
              />
            </div>
          </SwiperSlide>

          <CCarouselNavigationButtons distance-from-edge="100px" />
        </Swiper>
      </client-only>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .m-promotions {
    --width-solid-peek: v-bind(WIDTH_MOBILE_CAROUSEL);
    --width-carousel: v-bind(WIDTH_MOBILE_CAROUSEL);
    --height-carousel: v-bind(HEIGHT_MOBILE_CAROUSEL);

    @include screenMdAndUp {
      --width-solid-peek: v-bind(WIDTH_DESKTOP_SOLID_PEEK);
      --width-carousel: v-bind(WIDTH_DESKTOP_CAROUSEL);
      --height-carousel: v-bind(HEIGHT_DESKTOP_CAROUSEL);
    }

    background-color: v-bind('data.fields.base?.fields.backgroundColour');

    // this allows the faded edges of the desktop swiper to run off the screen
    overflow: hidden;
  }

  .promotional-carousel-wrapper {
    // give the right shape to the module
    aspect-ratio: var(--width-carousel) / var(--height-carousel);
    // allow the swiper absolute positioning
    position: relative;

    @include screenSmAndUp {
      min-height: 605px; // the calculated height of the carousel at 576px width
    }

    @include screenMdAndUp {
      aspect-ratio: max(var(--width-solid-peek) / var(--height-carousel));
      // prevent the module from scaling up further once XL width is reached
      min-height: 302px; // the calculated height of the carousel at 768px width
      max-height: calc(var(--height-carousel) * 1px);
      // centre the module and therefore the swiper
      margin-left: auto;
      margin-right: auto;
    }

    @include screenLgAndUp {
      min-height: 391px; // the calculated height of the carousel at 992px width
    }

    @include screenXlAndUp {
      height: 552px; // the calculated height of the carousel at 1440px width
    }
  }

  .promotional-carousel {
    @include screenMdAndUp {
      // add the faded edges
      mask: linear-gradient(to left, transparent 0%, #fff 5% 95%, transparent 100%);
      mask-repeat: no-repeat;
    }

    // position the swiper centred on top of the module
    // it has to be absolutely positioned so the faded edges off the sides of the screen don't take up page space
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) //
      // scale the swiper to keep the internal proportions of the slides consistent
      // min(1, ...) = maximum scale is 1 = don't scale up further once full size (XL screen width) is reached
      scale(min(1, v-bind(carouselScale)));
    transform-origin: top center;

    width: calc(var(--width-carousel) * 1px);
    height: auto;
    aspect-ratio: var(--width-carousel) / var(--height-carousel);
  }

  .promotional-slide {
    position: relative;

    // rounded corners
    border-radius: 24px;
    overflow: hidden;

    height: 100%;

    .desktop-image,
    .mobile-image {
      // position image under the text/buttons
      z-index: -1;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    // conditionally display images
    .desktop-image {
      display: none;
      @include screenMdAndUp {
        display: initial;
      }
    }

    .mobile-image {
      @include screenMdAndUp {
        display: none;
      }
    }

    .section-text {
      display: flex;
      flex-direction: column;
      height: 100%;

      .promotion-text-block {
        width: 100%;
        padding: 24px;
        flex: 1;

        @include screenMdAndUp {
          width: 60%;
          padding: 48px 112px 32px;
        }
      }

      .button-block {
        padding: 0 24px 24px;

        @include screenMdAndUp {
          padding: 0 112px 48px;
        }
      }
    }
  }

  // Content Alignment - Horizontal

  .section-text.prop-align-left {
    align-items: start;
    text-align: start;

    & .button-block {
      justify-content: flex-start;
    }
  }

  .section-text.prop-align-center {
    align-items: center;

    text-align: center;

    & .button-block {
      justify-content: center;
    }
  }

  .section-text.prop-align-right {
    align-items: end;

    text-align: end;

    & .button-block {
      justify-content: end;
    }
  }

  // Content Alignment: Vertical

  .section-text.prop-justify-start {
    & .promotion-text-block {
      justify-content: flex-start;
    }

    justify-content: start;
  }

  .section-text.prop-justify-center {
    & .promotion-text-block {
      justify-content: center;
    }

    justify-content: center;
  }

  .section-text.prop-justify-end {
    & .promotion-text-block {
      justify-content: flex-end;
    }

    justify-content: end;
  }

  // static text sizing

  // the mobile values are scaled to match the normal sizes at 544px, which is midway between 768 (biggest mobile screen width) and 320px (smallest mobile screen width we design for) - between 320 - 768 the text/buttons will be a bit smaller than normal, between 544 - 768 a bit bigger
  :deep(.text-overline) {
    font-size: 22px;
    line-height: 110%;
  }

  :deep(.display-1) {
    font-size: 52px;
    line-height: 120%;
  }

  :deep(.header-3) {
    font-size: 44px;
    line-height: 120%;
  }

  :deep(.header-4) {
    font-size: 34px;
    line-height: 140%;
  }

  :deep(.body) {
    font-size: 24px;
    line-height: 140%;
  }

  :deep(.normal-button) {
    font-size: 20px;
    padding: 16px 32px;
  }

  @include screenMdAndUp {
    :deep(.display-1) {
      font-size: 76px;
      line-height: 95%;
    }
    :deep(.header-3) {
      font-size: 64px;
      line-height: 100%;
    }
    :deep(.header-4) {
      font-size: 32px;
    }
    :deep(.body) {
      font-size: 20px;
    }
  }
</style>
